<template>
    <div v-if="showActionsPagePrompt" class="actions-page-prompt-container">
        <v-alert
            :value="true"
            icon="verified_user"
            color="primary"
            border="left"
            colored-border
            dismissible
            :elevation="4"
            @input="dismissActionsPagePrompt"
        >
            <span @click="trackActionsPageBannerClick">
                <router-link :to="{ name: 'actions' }" class="actions-page-prompt-link">
                    <span>
                        {{ $t('welcome', { name: currentUser.firstName }) }}
                        <u>{{ $t('prompt', { name: currentUser.firstName }) }}</u>
                    </span>
                </router-link>
            </span>
        </v-alert>
    </div>
</template>

<script lang="ts">
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import { trackActionsPageEvent } from '@/tracking';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                welcome: 'Welcome, {name}!',
                prompt: 'Check out some first actions you can take.',
            },
        },
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        showActionsPagePrompt(): boolean {
            const everViewedPage = this.currentUser?.userSettings?.lastViewedActionsPage;
            const ignored = this.currentUser?.userSettings?.ignoredActionsPrompt;
            return (this.currentUser !== null) && !everViewedPage && !ignored;
        },
    },

    methods: {
        resetViewedAndIgnoredState() {
            this.$store.dispatch('updateSettings', {
                lastViewedActionsPage: '',
                ignoredActionsPrompt: '',
            });
        },

        dismissActionsPagePrompt() {
            this.$store.dispatch('updateSettings', {
                ignoredActionsPrompt: new Date().toISOString(),
            });
        },

        trackActionsPageBannerClick() {
            trackActionsPageEvent('Clicked Actions Page Banner');
        },
    },
});
</script>

<style lang="postcss" scoped>
.actions-page-prompt-container {
    margin: 0 auto;
    max-width: 500px; /* Match post card. */
}

.actions-page-prompt-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
}
</style>
