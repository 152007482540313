<template>
    <div class="posts-detail px-4">
        <v-alert
            :value="error !== null"
            color="error"
            border="left"
            colored-border
            :elevation="2"
            class="mt-8"
        >
            <div class="ml-2">{{ $t('posts.notFound') }}</div>
        </v-alert>

        <post-card v-if="selectedPost" :post="selectedPost" :expanded-view="true" />

        <div v-if="isOwnPost" class="d-flex justify-center align-center">
            <!-- <base-button color="primary" text @click="editPost()">Edit</base-button> -->
        </div>
    </div>
</template>

<script lang="ts">
import { CurrentUser, Post } from '@/types';
import Vue from '@/vueTyped';
import PostCard from '@/components/PostCard.vue';

export default Vue.extend({
    name: 'PostDetail',
    metaInfo(): any {
        return {
            title: 'Post',
            meta: [
                {
                    name: 'robots',
                    content: 'noindex',
                },
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.selectedPost ? this.selectedPost.textBody.slice(0, 120) : '',
                },
                {
                    property: 'og:description',
                    content: this.selectedPost ? `${this.selectedPost.userObj.firstName} ${this.selectedPost.userObj.lastName} - ${this.selectedPost.textBody.slice(0, 120)}` : '',
                    vmid: 'og:description'
                },
                {
                    property: 'twitter:description',
                    content: this.selectedPost ? `${this.selectedPost.userObj.firstName} ${this.selectedPost.userObj.lastName} - ${this.selectedPost.textBody.slice(0, 120)}` : '',
                    vmid: 'twitter:description'
                },
                {
                    property: 'twitter:image',
                    content: this.selectedPost && this.selectedPost.photoObjs && this.selectedPost.photoObjs.length ? this.selectedPost.photoObjs[0].secure_url : '',
                    vmid: 'twitter:image',
                },
                {
                    property: 'og:image',
                    content: this.selectedPost && this.selectedPost.photoObjs && this.selectedPost.photoObjs.length ? this.selectedPost.photoObjs[0].secure_url : '',
                    vmid: 'og:image',
                },
            ],
        };
    },
    components: {
        PostCard,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            error: null as Error | null,
        };
    },
    computed: {
        isOwnPost(): boolean {
            if (this.selectedPost) {
                const currentUser = this.$store.state.account.currentUser;
                return !!currentUser && currentUser.id === this.selectedPost.user;
            }
            return false;
        },
        selectedPost(): Post {
            return this.$store.state.posts.selectedPost as Post;
        },
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        id: {
            immediate: true,
            async handler(id) {
                this.error = null;
                const { error } = await this.$store.dispatch('fetchPostById', { id });
                if (error) {
                    this.error = error;
                }
            },
        },
    },
    methods: {
        async editPost() {
        },
    },
});
</script>
<style lang="postcss" scoped>
.posts-detail {
    max-width: 60rem;
    margin: 5rem auto;
}
.posts-detail :deep(.post-card) {
    margin: 0 auto;
}

</style>
