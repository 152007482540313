<template>
    <div class="post-filters">
        <transition name="drop">
            <div v-if="$vuetify.breakpoint.xsOnly && presetsMenuOpen" class="presets-menu-visual-backdrop" />
        </transition>

        <div class="presets-menu" :data-speed-dial="$vuetify.breakpoint.xsOnly" :data-open="presetsMenuOpen">
            <div class="presets-menu-content">
                <div v-if="presetsMenuOpen" class="presets-menu-backdrop-click-catcher" @click="presetsMenuOpen = false" />

                <base-button v-if="$vuetify.breakpoint.xsOnly" small fab color="primary" @click="presetsMenuOpen = !presetsMenuOpen">
                    <v-icon>{{ presetsMenuOpen ? 'close' : 'room' }}</v-icon>
                </base-button>

                <template v-if="!$vuetify.breakpoint.xsOnly || presetsMenuOpen">
                    <template v-for="({ region, investigations }, i) in clientGroupsRegionsAndInvestigations">
                        <base-button
                            v-if="investigations.length < 2"
                            :key="[i, region.id, ...investigations.map(i => i.id)].join('-')"
                            :to="{ query: { regionId: region.id, investigationId: investigations[0] ? investigations[0].id : undefined } }"
                            outlined
                            :pressed="activePreset === ['client-group', region.id, ...investigations.map(i => i.id)].join('-')"
                            class="preset-button"
                            never-active
                            :aria-label="`${region.label} ${investigations[0] ? `(${investigations[0].name})` : ''}`"
                            @click="handlePresetButtonClick('region', { regionId: region.id });"
                        >
                            <template v-if="investigations[0]">{{ investigations[0].name }} in</template>
                            {{ region.label }}
                        </base-button>

                        <base-menu v-else :key="[region.id, ...investigations.map(i => i.id)].join('-')">
                            <template #activator="{ attrs, on }">
                                <base-button
                                    class="preset-button"
                                    outlined
                                    :pressed="activePreset === ['client-group', region.id, ...investigations.map(i => i.id)].filter(Boolean).join('-')"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ region.label }}
                                    <v-icon small class="ml-1">expand_more</v-icon>
                                </base-button>
                            </template>

                            <v-card class="text-start">
                                <v-list-item
                                    v-for="investigation in investigations"
                                    :key="investigation.id"
                                    :to="{ query: { regionId: region.id, investigationId: investigation.id } }"
                                    exact
                                >
                                    <v-list-item-title>{{ investigation.name }}</v-list-item-title>
                                </v-list-item>

                                <v-divider />

                                <v-list-item
                                    :to="{ query: { regionId: region.id } }"
                                    exact
                                >
                                    <v-list-item-title>{{ $t('posts.filters.allInvestigations') }}</v-list-item-title>
                                </v-list-item>
                            </v-card>
                        </base-menu>
                    </template>

                    <base-button
                        v-if="homeLocationQuery"
                        key="home"
                        :to="{ query: homeLocationQuery }"
                        :pressed="activePreset === 'home'"
                        outlined
                        class="preset-button"
                        never-active
                        :aria-label="$t('posts.filters.home')"
                        @click="handlePresetButtonClick('home', homeLocationQuery);"
                    >
                        <v-icon>home</v-icon>
                    </base-button>

                    <base-button
                        key="world"
                        :to="{ query: {} }"
                        :pressed="activePreset === 'world'"
                        outlined
                        class="preset-button"
                        never-active
                        @click="handlePresetButtonClick('world');"
                    >
                        {{ $t('posts.filters.world') }}
                    </base-button>

                    <base-button
                        key="current"
                        :to="currentLocationQuery ? { query: currentLocationQuery } : undefined"
                        :loading="gettingCurrentLocation"
                        :pressed="activePreset === 'current'"
                        outlined
                        class="preset-button"
                        never-active

                        @click="handlePresetButtonClick('current');"
                    >
                        {{ $t('posts.filters.currentLocation') }}
                    </base-button>

                    <base-button
                        key="search"
                        :pressed="activePreset === 'search'"
                        outlined
                        class="preset-button"
                        never-active
                        :aria-label="$t('posts.filters.search')"
                        @click="searchModalOpen = true"
                    >
                        <v-icon>search</v-icon>
                    </base-button>
                </template>
            </div>
        </div>

        <overlay-modal v-model="searchModalOpen" max-width="600px">
            <template #title>
                <div>
                    {{ $t('posts.searchDialog.title') }}
                </div>
            </template>

            <template #extra>
                <base-button v-if="hasSearchFormInput" text :color="$vuetify.breakpoint.xsOnly ? null : 'primary'" @click="clearSearchForm">{{ $t('actions.clear') }}</base-button>
            </template>

            <template v-if="currentUser" #content>
                <form id="post-filters-search-form" @submit.prevent="handleSearchFormSubmission">
                    <p v-if="!hasSearchFormInput" class="body-2 t-sans">{{ $t('posts.searchDialog.pickOne') }}</p>

                    <base-fieldset v-if="clientGroupRegions.length !== 0">
                        <template #legend>Region</template>

                        <v-radio-group v-model="searchFormValues.region" row hide-details="" class="mt-0 mb-4">
                            <v-radio :value="false">
                                <template #label>Any region</template>
                            </v-radio>

                            <v-radio v-for="region in clientGroupRegions" :key="region.id" :value="region.id">
                                <template #label>{{ region.label }}</template>
                            </v-radio>
                        </v-radio-group>
                    </base-fieldset>

                    <div class="my-4">
                        <location-selector
                            v-model="searchFormValues.location"
                            :label="$t('posts.searchDialog.location')"
                            :hint="$t('posts.searchDialog.locationHint')"
                        />
                    </div>

                    <div class="my-4">
                        <base-select
                            v-if="searchFormValues.location"
                            v-model="searchFormValues.distance"
                            class="distance-select"
                            :label="$t('posts.searchDialog.distance')"
                            :items="distanceOptions"
                            return-object
                            item-text="label"
                            item-value="value"
                        />
                    </div>

                    <div class="my-4">
                        <base-select
                            v-model="searchFormValues.investigation"
                            :label="$t('posts.searchDialog.investigations')"
                            hide-details
                            :items="investigationOptions"
                            return-object
                            item-text="name"
                            item-value="id"
                        />
                    </div>

                    <div v-if="searchFormValues.investigation">
                        <template v-for="question in searchFormValues.investigation.structuredQuestions">
                            <v-checkbox
                                v-if="question.featured"
                                :key="question.id"
                                v-model="searchFormValues.questions"
                                :value="question.id"
                                :label="$t('posts.searchDialog.includesAnswer', { question: question.title })"
                                hide-details
                            />
                        </template>
                    </div>

                    <div class="my-4">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <base-date-picker
                                    v-model="searchFormValues.fromDate"
                                    :label="$t('posts.searchDialog.from')"
                                    hide-details
                                    clearable
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <base-date-picker
                                    v-model="searchFormValues.toDate"
                                    :label="$t('posts.searchDialog.to')"
                                    max="now"
                                    hide-details
                                    clearable
                                />
                            </v-col>
                        </v-row>
                    </div>

                    <div class="my-4">
                        <base-input
                            v-model="searchFormValues.search"
                            :label="$t('posts.searchDialog.keywords')"
                            type="text"
                        />
                    </div>
                </form>
            </template>

            <template v-else #content>
                <markdown-output :value="$t('posts.searchDialog.notSignedIn')" />
            </template>

            <template #actions>
                <base-button v-if="currentUser" type="submit" form="post-filters-search-form" prepend-icon="check" rounded color="primary">
                    {{ $t('posts.searchDialog.submit') }}
                </base-button>

                <template v-else>
                    <auth-button rounded color="primary">
                        {{ $t('actions.login') }}
                    </auth-button>
                </template>
            </template>
        </overlay-modal>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
// import debounce from 'lodash/debounce';
import { CurrentUser, Investigation, LngLat, MBAddressObj, PostQueryParameters, Region } from '@/types';
import OverlayModal from '@/layouts/OverlayModal.vue';
import LocationSelector from '@/components/location/LocationSelector.vue';
import { reverseGeocode } from '@/util.mapbox';
import { trackSightingsFeedFilter } from '@/tracking';
import orderBy from 'lodash/orderBy';
import BaseFieldset from './BaseFieldset.vue';
import { TranslateResult } from 'vue-i18n';
import MarkdownOutput from './MarkdownOutput.vue';
import AuthButton from './AuthButton.vue';
// import moment from 'moment';

let startWithMenuOpen = true;

type DistanceOption = {
    value: string;
    label: TranslateResult;
    isDefault?: true;
};

export default Vue.extend({
    components: {
        OverlayModal,
        LocationSelector,
        BaseFieldset,
        MarkdownOutput,
        AuthButton,
    },

    data() {
        return {
            presetsMenuOpen: startWithMenuOpen,
            gettingCurrentLocation: false,
            searchModalOpen: false,
            searchFormValues: {
                region: false as string | false,
                location: null as MBAddressObj | null,
                distance: null as DistanceOption | null,
                investigation: null as Investigation | null,
                questions: [] as string[],
                fromDate: '',
                toDate: '',
                search: '',
            },
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        activePreset(): string {
            const queryParamsCount = Object.entries(this.$route.query).filter(([key, value]) => {
                // TODO: This is sloppy, we should understand which keys we're looking for.
                return Boolean(value) && key !== 'sort';
            }).length;

            // TODO: This doesn't work for Region menus.
            const queriedRegion = this.clientGroupsRegionsAndInvestigations.find(({ region, investigations }) => {
                const investigationMatches = this.$route.query.investigationId && investigations.find(i => i.id === this.$route.query.investigationId);
                return investigationMatches && region.id === this.$route.query.regionId;
            });

            if (queryParamsCount <= 2 && queriedRegion) {
                return ['client-group', this.$route.query.regionId, this.$route.query.investigationId].filter(Boolean).join('-');
            } else if (this.homeLocationQuery && this.$route.query.near === this.homeLocationQuery.near) {
                return 'home';
            } else if (queryParamsCount === 0) {
                return 'world';
            } else if (this.currentLocationQuery && this.$route.query.near === this.currentLocationQuery.near) {
                return 'current';
            } else {
                return 'search';
            }
        },

        clientGroupRegions(): Region[] {
            const allRegions = this.currentUser?.clientGroups?.flatMap(group => group.regions) ?? [];
            return orderBy(Array.from(new Set(allRegions)), 'label');
        },

        clientGroupsRegionsAndInvestigations(): { region: Region, investigations: Investigation[] }[] {
            const results = [];

            for (const group of this.currentUser?.clientGroups ?? []) {
                for (const region of group.regions) {
                    results.push({
                        region,
                        investigations: group.investigations.map(fromGroup => {
                            return this.$store.state.investigations.items.find((fromState: Investigation) => fromState.id === fromGroup.id);
                        }).filter(Boolean) as Investigation[],
                    });
                }
            }

            return results;
        },

        // Note on the `toFixed` calls here: by fixing known location coordinates to fewer decimal places,
        // we can differentiate them from locations from the search dialog.

        homeLocationQuery(): PostQueryParameters | null {
            if (this.currentUser?.userSettings?.homeSightingsQuery) {
                const query = JSON.parse(this.currentUser?.userSettings.homeSightingsQuery);
                const oldParamsFormatStillStored = 'lon' in query;
                if (oldParamsFormatStillStored) {
                    return {
                        near: [query.lon, query.lat, query.distance].join(','),
                    };
                } else {
                    return query;
                }
            } else if (this.currentUser?.lng && this.currentUser?.lat) {
                return {
                    near: [
                        this.currentUser.lng.toFixed(4),
                        this.currentUser.lat.toFixed(4),
                        this.defaultDistanceOption.value,
                    ].join(','),
                };
            } else {
                return null;
            }
        },

        currentLocationQuery(): PostQueryParameters | null {
            if (this.$store.state.activeLocation) {
                return {
                    near: [
                        this.$store.state.activeLocation.center[0].toFixed(4),
                        this.$store.state.activeLocation.center[1].toFixed(4),
                        this.defaultDistanceOption.value,
                    ].join(','),
                };
            } else if (localStorage.mostRecentCurrentLocationQuery) {
                // We'll store the most recent current location locally
                // so we don't have to request it for the button to be active when the page loads.
                return JSON.parse(localStorage.mostRecentCurrentLocationQuery);
            } else {
                return null;
            }
        },

        searchQuery(): any {
            const result: any = {
                regionId: this.searchFormValues.region || undefined,
                investigationId: this.searchFormValues.investigation?.id || undefined,
                structuredQuestion: this.searchFormValues.questions.length !== 0 ? this.searchFormValues.questions : undefined,
                fromDate: this.searchFormValues.fromDate || undefined,
                toDate: this.searchFormValues.toDate || undefined,
                search: this.searchFormValues.search.trim() || undefined,
            };

            if (this.searchFormValues.location) {
                result.near = [
                    this.searchFormValues.location.center[0].toFixed(6),
                    this.searchFormValues.location.center[1].toFixed(6),
                    this.searchFormValues.distance?.value ?? undefined,
                ].join(',');
            }

            for (const [key, value] of Object.entries(result)) {
                if (value === undefined || (Array.isArray(value) && value.length === 0)) {
                    delete result[key];
                }
            }

            return result;
        },

        hasSearchFormInput(): boolean {
            return Object.values(this.searchQuery).some(Boolean);
        },

        distanceOptions(): DistanceOption[] {
            const availableDistances: DistanceOption[] = [];

            if (this.currentUser?.userSettings?.preferredSystemOfMeasure === 'METRIC') {
                if (this.clientGroupRegions.length !== 0) {
                    availableDistances.push(
                        { value: '100m', label: this.$tc('posts.searchDialog.distanceIn.m', 100) },
                        { value: '250m', label: this.$tc('posts.searchDialog.distanceIn.m', 250) },
                        { value: '500m', label: this.$tc('posts.searchDialog.distanceIn.m', 500) },
                        { value: '1km', label: this.$tc('posts.searchDialog.distanceIn.km', 1) },
                    );
                }

                availableDistances.push(
                    { value: '10km', label: this.$tc('posts.searchDialog.distanceIn.km', 10) },
                    { value: '25km', label: this.$tc('posts.searchDialog.distanceIn.km', 25), isDefault: true },
                    { value: '100km', label: this.$tc('posts.searchDialog.distanceIn.km', 100) },
                    { value: '200km', label: this.$tc('posts.searchDialog.distanceIn.km', 200) },
                );
            } else {
                if (this.clientGroupRegions.length !== 0) {
                    availableDistances.push(
                        { value: '500ft', label: this.$tc('posts.searchDialog.distanceIn.ft', 500) },
                        { value: '1000ft', label: this.$tc('posts.searchDialog.distanceIn.ft', 1000) },
                        { value: '1mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 1) },
                    );
                }

                availableDistances.push(
                    { value: '5mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 5) },
                    { value: '20mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 20), isDefault: true },
                    { value: '50mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 50) },
                    { value: '200mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 200) },
                );
            }

            return availableDistances;
        },

        defaultDistanceOption(): DistanceOption {
            const middleFallback = this.distanceOptions[Math.floor((this.distanceOptions.length - 1) / 2)];
            return this.distanceOptions.find(option => option.isDefault) ?? middleFallback;
        },

        investigationOptions(): Investigation[] {
            const none = { id: '', name: 'All Investigations' } as Investigation;
            return [none].concat(this.$store.state.investigations.items);
        },
    },

    watch: {
        ['$route.query']: {
            deep: true,
            immediate: true,
            handler() {
                this.syncSearchFormValues();
            },
        },

        activePreset(activePreset: string) {
            if (this.currentUser) {
                const sightingsQuery = activePreset === 'search' ? this.currentUser.userSettings?.homeSightingsQuery : JSON.stringify(this.$route.query);
                this.$store.dispatch('updateSettings', { sightingsQuery });
            }
        },

        'searchFormValues.investigation'() {
            this.searchFormValues.questions = [];
        },
    },

    async mounted() {
        await this.$store.dispatch('fetchInvestigations');

        this.syncSearchFormValues();

        setTimeout(() => {
            this.presetsMenuOpen = false;
            startWithMenuOpen = false;
        }, 333);
    },

    methods: {
        handlePresetButtonClick(button: string, query: PostQueryParameters = {}) {
            if (button === 'current') {
                if (!this.$store.state.activeLocation) {
                    this.getCurrentLocation();
                    return;
                } else {
                    query = this.currentLocationQuery!;
                }
            }

            this.presetsMenuOpen = false;
            trackSightingsFeedFilter(button, query);
        },

        async getCurrentLocation() {
            this.gettingCurrentLocation = true;

            let { data: { error } } = await this.$store.dispatch('getActiveLocation');

            if (this.currentLocationQuery) {
                try {
                    // Cache this locally so the preset button works on reload.
                    localStorage.mostRecentCurrentLocationQuery = JSON.stringify(this.currentLocationQuery);
                } catch (ignoredError) {
                    // No worries, the query will just appear as a search by location.
                }

                this.$router.push({ query: this.currentLocationQuery as any });
                trackSightingsFeedFilter('current', this.currentLocationQuery);
            } else {
                error = error ?? 'Failed to find your current location.'

                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.currentUser ? error : 'Sign in to see your local feed!',
                });
            }

            this.gettingCurrentLocation = false;
            this.presetsMenuOpen = false;
        },

        async syncSearchFormValues() {
            this.searchFormValues.region = this.$route.query.regionId as string ?? false;

            this.searchFormValues.investigation = this.investigationOptions.find(i => i.id === this.$route.query.investigationId) ?? this.investigationOptions[0] as Investigation;

            this.searchFormValues.questions = Array.isArray(this.$route.query.structuredQuestion) ? (this.$route.query.structuredQuestion as string[]) : this.$route.query.structuredQuestion ? [this.$route.query.structuredQuestion] : [];

            if (this.activePreset !== 'search') {
                return;
            }

            this.searchFormValues.fromDate = this.$route.query.fromDate as string ?? '';
            this.searchFormValues.toDate = this.$route.query.toDate as string ?? '';
            this.searchFormValues.search = this.$route.query.search as string ?? '';

            if (this.$route.query.near) {
                const [lng, lat, distance] = this.$route.query.near.split(',');

                this.searchFormValues.distance = this.distanceOptions.find(d => String(d.value) === distance) ?? this.defaultDistanceOption;

                const coords: LngLat = [parseFloat(lng), parseFloat(lat)];
                const lngChanged = coords[0] !== this.searchFormValues.location?.center[0];
                const latChanged = coords[1]  !== this.searchFormValues.location?.center[1];

                if (lngChanged || latChanged) {
                    const reverseGeocodeCoords = await reverseGeocode(coords, { limit: 1 });
                    this.searchFormValues.location = reverseGeocodeCoords.features[0];
                }
            } else if (this.$route.query.lon && this.$route.query.lat) {
                const coords: LngLat = [
                    parseFloat(this.$route.query.lon as string),
                    parseFloat(this.$route.query.lat as string),
                ];

                this.searchFormValues.distance = this.distanceOptions.find(d => String(d.value) === this.$route.query.distance) ?? this.defaultDistanceOption;

                const lngChanged = coords[0] !== this.searchFormValues.location?.center[0];
                const latChanged = coords[1]  !== this.searchFormValues.location?.center[1];

                if (lngChanged || latChanged) {
                    const reverseGeocodeCoords = await reverseGeocode(coords, { limit: 1 });
                    this.searchFormValues.location = reverseGeocodeCoords.features[0];
                }
            } else {
                this.searchFormValues.location = null;
                this.searchFormValues.distance = this.defaultDistanceOption;
            }
        },

        handleSearchFormSubmission() {
            this.searchModalOpen = false;
            this.$router.push({ query: this.searchQuery });
            trackSightingsFeedFilter('search', this.searchQuery);
            this.presetsMenuOpen = false;
        },

        clearSearchForm() {
            Object.assign(this.searchFormValues, {
                region: false,
                location: null,
                distance: this.defaultDistanceOption,
                investigation: this.investigationOptions[0],
                questions: [],
                fromDate: '',
                toDate: '',
                search: '',
            });
        },
    },
});
</script>

<style scoped>
.post-filters {
    --pressed-button-color: var(--color-primary);
}

.drop-enter-active,
.drop-leave-active {
    transition: opacity 0.3s, transform 0.4s;
}

.drop-enter,
.drop-leave-to {
    opacity: 0;
    transform: translateY(-2em);
}

.presets-menu:not([data-speed-dial]) {
    text-align: center;
}

.presets-menu[data-speed-dial] {
    margin: calc(env(safe-area-inset-top) + 55px + var(--spacing-3)) 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: var(--spacing-1) var(--spacing-3);
    position: fixed;
    right: 0;
    top: var(--weather-prompt-height, 0);
    z-index: 4;
}

.presets-menu[data-speed-dial][data-open] {
    bottom: 0;
    left: 0;
}


[data-speed-dial] .presets-menu-content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translateZ(0);
}

.presets-menu-visual-backdrop {
    background: linear-gradient(#8886 90%, #8880);
    bottom: -1000vh;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
}

.presets-menu-backdrop-click-catcher {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

/* An extra class for specificity over Vuetify: */
.post-filters .preset-button {
    font-size: var(--type-interface);
    margin: var(--spacing-1);
}

.post-filters .preset-button :deep(.v-btn__content) {
    flex-shrink: 1;
    white-space: normal;
}

.presets-menu .preset-button {
    border-radius: 16px;
    height: auto;
    min-height: 32px;
    padding-block: 5px;
}
</style>
