<template>
    <div class="guest-feed" :style="{ '--cutoff': `${cutoff}px` }">
        <div ref="measure" class="measure" />

        <div ref="columns" class="posts-cols">
            <div v-for="i in columnsCount" :key="i" class="posts-col" />
        </div>

        <loading-indicator v-if="fetching" />

        <div v-else class="cta">
            <binoculars />
            <div class="mb-4">{{ $t('seeMore') }}</div>
            <auth-button large color="primary">{{ $t('signUp') }}</auth-button>
        </div>

        <div ref="postsHolder" class="posts-holder">
            <post-card
                v-for="post, i of posts"
                :key="post.id"
                class="post"
                :post="post"
                :data-index="i"
                :data-id="post.id"
            />
        </div>
    </div>
</template>

<script lang="ts">
import AuthButton from '@/components/AuthButton.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PostCard from '@/components/PostCard.vue';
import RouteNames from '@/router/names';
import { Post } from '@/types';
import { aggregatePostData } from '@/util.posts';
import { defineComponent } from 'vue';
import Binoculars from './Binoculars.vue';

export default defineComponent({
    components: {
        AuthButton,
        Binoculars,
        LoadingIndicator,
        PostCard,
    },

    i18n: {
        messages: {
            en: {
                seeMore: 'Wanna see more? Sign Up for ISeeChange!',
                signUp: 'Sign up',
            },

            es: {
                seeMore: '¿Quiero ver más? ¡Regístrese en ISeeChange!',
                signUp: 'Inscribirse',
            },
        },
    },

    data() {
        return {
            columnsCount: 0,
            cutoff: Infinity,
            fetching: false,
            posts: [] as Post[],
        };
    },

    computed: {
        RouteNames(): typeof RouteNames {
            return RouteNames;
        },
    },

    watch: {
        posts: 'redrawColumns',
    },

    mounted() {
        this.fetchPosts();
        addEventListener('resize', this.redrawColumns);
    },

    beforeDestroy() {
        removeEventListener('resize', this.redrawColumns);
    },

    methods: {
        async fetchPosts() {
            if (this.fetching) return;
            try {
                this.fetching = true;
                const response = await this.$store.getters.apiClient.get('/posts/recent-selection');
                if (response.status !== 200) {
                    throw new Error('Failed to fetch posts');
                }
                this.posts = await aggregatePostData(
                    this.$store,
                    response.data.posts,
                    response.data.photos,
                    response.data.investigations,
                    response.data.users,
                    response.data.comments,
                    response.data.weatherUnits
                );
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', { type: 'error', message: 'Could not fetch posts' });
            } finally {
                this.fetching = false;
            }
        },

        async redrawColumns() {
            const MAX_COLUMNS = 50;

            if (!(this.$refs.columns instanceof HTMLElement) || !(this.$refs.measure instanceof HTMLElement)) return;

            const columnsCountIs = this.columnsCount;
            const columnsCountWillBe = Math.max(1, Math.min(MAX_COLUMNS, Math.floor(this.$refs.columns.clientWidth / this.$refs.measure.offsetWidth)));

            if (columnsCountWillBe !== columnsCountIs || !isFinite(this.cutoff)) {
                this.restorePosts();
                this.columnsCount = columnsCountWillBe;
                await this.$nextTick(); // Wait for the columns to be created/destroyed.
                this.distributePosts();
            }

            this.resetCutoff();
        },

        restorePosts() {
            if (!(this.$refs.postsHolder instanceof HTMLElement)) return;
            const postElements = Array.from(this.$el.querySelectorAll<HTMLElement>(`[data-index][data-id]`));
            postElements.sort((e1, e2) => parseFloat(e1.dataset.index ?? '') - parseFloat(e2.dataset.index ?? ''));
            this.$refs.postsHolder.append(...postElements);
        },

        distributePosts() {
            if (!(this.$refs.postsHolder instanceof HTMLElement) || !(this.$refs.columns instanceof HTMLElement)) return;

            const postElements = Array.from(this.$el.querySelectorAll<HTMLElement>(`[data-index][data-id]`));
            const columns = Array.from(this.$refs.columns.children);

            for (const postElement of postElements) {
                const [shortestColumn] = columns.sort((c1, c2) => c1.scrollHeight - c2.scrollHeight);
                shortestColumn?.append(postElement);
            }
        },

        resetCutoff() {
            if (!(this.$refs.columns instanceof HTMLElement)) return;
            const columns = Array.from(this.$refs.columns.children);
            const [shortestColumn] = columns.sort((c1, c2) => c1.scrollHeight - c2.scrollHeight);
            this.cutoff = shortestColumn.scrollHeight === 0 ? NaN : shortestColumn.scrollHeight;
        },
    },
});
</script>

<style scoped>
.guest-feed {
    --column-width: 30rem;
    --gap: 40px;
}

.measure {
    height: 1px;
    margin-bottom: -1px;
    width: calc(var(--column-width) + var(--gap));
    max-width: 100%;
}

.posts-cols {
    align-items: start;
    display: flex;
    gap: var(--gap);
    justify-content: center;
    padding: 24px;
    mask: linear-gradient(black 75%, transparent);
    max-height: calc(var(--cutoff) - 30px);
    overflow: hidden;
}

.posts-col {
    max-width: 100%;
    width: var(--column-width);
}

.posts-col > :first-child {
    margin-block-start: 0;
}

.posts-col > :last-child {
    margin-block-end: 0;
}

.post {
    margin-block: var(--gap);
}

.cta {
    border-block-start: 1px solid #8883;
    padding: 56px 24px;
    text-align: center;
}

.posts-holder {
    display: none;
}
</style>
