<template>
    <base-custom-input :hint="$attrs.hint">
        <template #label>
            <slot name="label">{{ $attrs.label }}</slot>
        </template>

        <div class="one-row">
            <div>
                <base-input v-model="feet" type="number" inputmode="numeric" :suffix="$t('feet')" :min="0" hide-details @blur="handleBlur" />
            </div>

            <div>
                <base-input v-model="inches" type="number" inputmode="numeric" :suffix="$t('inches')" :min="0" hide-details @blur="handleBlur" />
            </div>
        </div>
    </base-custom-input>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    i18n: {
        messages: {
            en: {
                feet: 'FEET',
                inches: 'INCHES',
            },

            es: {
                feet: 'PIES',
                inches: 'PULGADAS',
            },
        },
    },

    props: {
        value: { type: Number, default: undefined },
    },

    data() {
        return {
            feet: '',
            inches: '',
        };
    },

    computed: {
        empty(): boolean {
            return this.feet === '' && this.inches === '';
        },
    },

    watch: {
        feet: 'emitValue',
        inches: 'emitValue',
    },

    mounted() {
        if (this.value !== undefined) {
            this.factorValue();
        }
    },

    methods: {
        emitValue() {
            if (this.empty) {
                this.$emit('input', undefined);
            } else {
                const feet = parseFloat(this.feet) || 0;
                const inches = parseFloat(this.inches) || 0;
                this.$emit('input', feet * 12 + inches);
            }
        },

        handleBlur() {
            if (!this.empty) {
                this.factorValue();
            }
        },

        factorValue() {
            const value = this.value || 0;
            this.feet = String(Math.floor(value / 12) || '');
            this.inches = String(value % 12);
        },
    },
});
</script>

<style scoped>
.one-row {
    display: flex;
    gap: var(--spacing-4);
}

.one-row > * {
    flex-grow: 1;
}

:deep(.v-text-field) {
    padding-top: 0;
}
</style>
