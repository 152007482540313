<template>
    <guest-feed v-if="!currentUser" />

    <!--This extra div prevents a horizontal-scrolling bug in the mobile iOS app.-->
    <div v-else>
        <div class="posts px-4" data-test-id="Sightings page">
            <actions-page-prompt />

            <h1 v-if="$vuetify.breakpoint.smAndUp" class="t-title mt-10 mb-4 text-center px-4">{{ $t('posts.headline') }}</h1>

            <posts-filters :class="{ 'my-8': $vuetify.breakpoint.smAndUp }" />

            <posts-list
                unique-id="primary-post-list"
                :user-id="null"
                :investigation-id="$route.query.investigationId || null"
                :structured-question="$route.query.structuredQuestion ? [].concat($route.query.structuredQuestion) : null"
                :region-id="$route.query.regionId || null"
                :from-date="$route.query.fromDate || null"
                :to-date="$route.query.toDate || null"
                :search="$route.query.search || null"
                :lng-lat="locationQuery.lngLat || null"
                :distance="locationQuery.distance || null"
            />
        </div>
    </div>
</template>

<script lang="ts">
import PostsFilters from '@/components/PostsFilters.vue';
import PostsList from '@/components/PostsList.vue';
import Vue from '@/vueTyped';
import ActionsPagePrompt from './ActionsPagePrompt.vue';
import GuestFeed from './GuestFeed.vue';

export default Vue.extend({
    metaInfo(): any {
        return {
            title: 'Sightings',
            meta: [{
                name: 'robots',
                content: 'noindex',
            }],
        };
    },

    components: {
        ActionsPagePrompt,
        GuestFeed,
        PostsList,
        PostsFilters,
    },

    data() {
        return {};
    },

    computed: {
        locationQuery(): Record<string, unknown> {
            if (this.$route.query.lon && this.$route.query.lat) {
                // We previously used separate lon/lat/distance params,
                // but Facebook complains when we send users' home coordinates.
                return {
                    lngLat: [parseFloat(this.$route.query.lon ?? 0), parseFloat(this.$route.query.lat ?? 0)],
                    distance: this.$route.query.distance || '1mi',
                };
            } else if (this.$route.query.near) {
                const [lng, lat, distance] = this.$route.query.near.split(',');
                return {
                    lngLat: [parseFloat(lng ?? 0), parseFloat(lat ?? 0)],
                    distance: distance || '1mi',
                };
            } else {
                return {};
            }
        },

        currentUser() {
            return this.$store.state.account.currentUser;
        },

        postsLoaded(): boolean {
            return this.$store.state.posts.asyncStatus === 'success';
        },
    },

    methods: {},
});
</script>

<style lang="postcss" scoped>
.posts {
    max-width: 60rem;
    margin: 1em auto;
}
</style>
