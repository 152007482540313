<!-- This component encapsulates shared behavior from the two UIs of pages/PostCreate, mutating the object prop is okay. -->
<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div class="details">
        <p v-if="investigation && investigation.structuredQuestionTitle" class="details__title">
            {{ structuredQuestionTitle }}
        </p>

        <v-row>
            <v-col
                v-for="question in allQuestions"
                :key="question.id"
                :data-question-type="question.dataType"
                :cols="$vuetify.breakpoint.mdAndUp && question.additionalInfo.half_width ? 6 : 12"
            >
                <!-- We're just using this loop to define constants in the template. -->
                <template v-for="[component, props] of [[questionComponent(question), questionProps(question)]]">
                    <component
                        :is="component"
                        :key="`${question.id} ${typeof props}`"
                        v-model="answers[question.id]"
                        v-bind="{ ...props, hint: undefined }"
                        class="mb-6"
                        :suffix="question.additionalInfo.translate_unit && question.additionalInfo.translate_unit[question.unit] || question.unit"
                        :data-test-id="`Structured question ${question.title} field`"
                        hide-details
                    >
                        <template v-if="component.computed && component.computed.HANDLES_OWN_HINT" #hint>
                            <markdown-output
                                v-if="props.hint"
                                :key="`${question.id} ${typeof props} hint`"
                                :value="props.hint" trusted
                                class="question-hint handled-by-component"
                            />
                        </template>
                    </component>

                    <!-- Take over the display of hints so we can use Markdown. -->
                    <markdown-output
                        v-if="props.hint && !(component.computed && component.computed.HANDLES_OWN_HINT)"
                        :key="`${question.id} ${typeof props} hint`"
                        :value="props.hint" trusted
                        class="question-hint"
                    />
                </template>
            </v-col>
        </v-row>

        <pre v-if="$route.query.answers">{{ answers }}</pre>
    </div>
</template>
<script lang="ts">
import BaseInput from '@/components/BaseInput.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import TimeRangePicker from '@/components/TimeRangePicker.vue';
import Vue from '@/vueTyped';
import { Investigation, QuestionDataType, StructuredQuestion } from '../../types';
import FeetInchesInput from '../FeetInchesInput.vue';
import MarkdownOutput from '../MarkdownOutput.vue';
import SearchInput from '../SearchInput.vue';
import SelectListInput from '../SelectListInput.vue';

export default Vue.extend({
    components: {
        MarkdownOutput
    },

    props: {
        investigation: {
            type: Object as () => Investigation | null,
            default: null,
        },
        structuredQuestions: {
            type: Array as () => StructuredQuestion[] | null,
            default: null,
        },
        answers: {
            type: Object as any,
            default: () => ({}),
        },
        baseDate: {
            type: Object,
            default: null,
        },
    },
    computed: {
        allQuestions(): StructuredQuestion[] {
            const investigationQuestions = this.investigation ? this.investigation.structuredQuestions : [];
            return this.structuredQuestions ?? investigationQuestions;
        },
    },
    methods: {
        questionComponent(question: StructuredQuestion) {
            if (question.dataType === QuestionDataType.DATETIME) {
                return 'base-date-picker';
            } else if ([QuestionDataType.DECIMAL, QuestionDataType.NUMERIC].includes(question.dataType)) {
                if (question.additionalInfo.translate_unit?.[question.unit] === 'FEET_AND_INCHES') {
                    return FeetInchesInput;
                }
                return BaseInput;
            } else if (question.dataType === QuestionDataType.BOOLEAN) {
                return BaseSelect;
            } else if (question.dataType === QuestionDataType.SELECT) {
                if (question.additionalInfo.options_source) {
                    return SearchInput;
                } else if (question.additionalInfo.list) {
                    return SelectListInput;
                } else {
                    return BaseSelect;
                }
            } else if (question.dataType === QuestionDataType.TIMERANGE) {
                return TimeRangePicker;
            } else {
                return BaseInput;
            }
        },

        questionProps(q: StructuredQuestion) {
            let props: any = {
                label: q.title,
                hint: [q.description, q.warning].join('\n').trim(),
                min: q.additionalInfo.min,
                max: q.additionalInfo.max,
            };

            switch (q.dataType) {
                case QuestionDataType.DECIMAL:
                    props = {
                        ...props,
                        type: 'number',
                        inputmode: 'decimal',
                        step: 0.1,
                    };
                    break;
                case QuestionDataType.BOOLEAN:
                    props = {
                        ...props,
                        items: [
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                        ],
                        returnObject: true,
                    };
                    break;
                case QuestionDataType.NUMERIC:
                    props = {
                        ...props,
                        type: 'number',
                        inputmode: 'numeric',
                        step: 1,
                    };
                    break;
                case QuestionDataType.DATETIME:
                    props = {
                        ...props,
                        type: 'time',
                        clearable: true,
                    };
                    break;
                case QuestionDataType.SELECT:
                    if (q.additionalInfo.options_source) {
                        props = {
                            ...props,
                            source: q.additionalInfo.options_source,
                            acceptTextInput: q.additionalInfo.accept_text_input,
                            menuProps: { maxHeight: 160, offsetOverflow: false },
                        };
                    } else if (q.additionalInfo.list) {
                        props = {
                            ...props,
                            options: q.additionalInfo.options,
                            multiple: q.additionalInfo.multiple,
                        };
                    } else {
                        props = {
                            ...props,
                            items: q.additionalInfo.options,
                            returnObject: true,
                            itemText: 'label',
                        };
                    }
                    break;
                default:
                    break;
            }
            return props;
        },
    },
});
</script>
<style lang="postcss" scoped>
/* Hacking around a bit to set the widths of these inputs: */

[data-question-type="DATETIME"] :deep(.v-input__slot),
[data-question-type="DECIMAL"] :deep(.v-input__slot),
[data-question-type="LITERAL"] :deep(.v-input__slot),
[data-question-type="NUMERIC"] :deep(.v-input__slot) {
    max-width: 20ch !important;
}

[data-question-type="SELECT"] :deep(.value-text) {
    max-width: 30ch !important;
}

:deep(.v-label) {
    max-width: none !important;
}

.question-hint {
    color: #0009;
    font-size: var(--type-small);
    line-height: 1.3;
}

.question-hint:not(.handled-by-component) {
    margin-top: -1em;
}
</style>
