<template>
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="600px"
        content-class="location-selector-dialog-root"
    >
        <template #activator="{ attrs, on }">
            <div class="activator" v-bind="attrs" v-on="on">
                <template v-if="displayLocation">
                    <base-text-area
                        readonly
                        :label="label"
                        :value="displayLocation"
                        :hint="hint"
                        :persistent-hint="!!hint"
                        no-resize
                        rows="1"
                        auto-grow
                    >
                        <template v-if="hint" #message>
                            <span class="font-italic">{{ hint }}</span>
                        </template>
                        <template #append>
                            <button type="button" class="activator__action" @click.prevent="">
                                {{ $t('locationSelection.change') }}
                            </button>
                        </template>
                    </base-text-area>
                </template>
                <template v-else>
                    <base-input
                        class="activator--empty-prompt"
                        readonly
                        :label="label"
                        :hint="hint"
                        :persistent-hint="!!hint"
                        @click:append="dialog = true"
                    >
                        <template v-if="hint" #message>
                            <span class="font-italic">{{ hint }}</span>
                        </template>
                        <template #append>
                            <button type="button" class="activator__action" @click.prevent="">
                                {{ $t('locationSelection.set') }}
                            </button>
                        </template>
                    </base-input>
                </template>
            </div>
        </template>

        <div class="dialog-content">
            <location-autocomplete
                v-model="internalValue"
                class="autocomplete"
                label="Location"
                :geocoding-param-types="$attrs['geocoding-param-types']"
                solo
            />

            <base-button class="close-button" fab x-small :aria-label="$t('actions.cancel')" @click="discardMapLocation">
                <v-icon>close</v-icon>
            </base-button>

            <div ref="mapselector" class="map" />

            <base-button v-if="internalValue || internalValue !== value" class="confirm-button" rounded color="primary" @click="confirmMapLocation">
                <template v-if="internalValue">{{ $t('locationSelection.confirm') }}</template>
                <template v-else>{{ $t('locationSelection.clear') }}</template>
            </base-button>
        </div>
    </v-dialog>
</template>
<script lang="ts">
import Vue from '@/vueTyped';
import { LngLat, MBAddressObj } from '@/types';
import debounce from 'lodash/debounce';
import { importMapboxGl, reverseGeocode } from '@/util.mapbox';
import LocationAutocomplete from '@/components/location/LocationAutocomplete.vue';

const CENTER_OF_THE_USA = [-98.5, 39.76];

export default Vue.extend({
    name: 'LocationSelector',
    components: {
        LocationAutocomplete,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: Object as () => MBAddressObj,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: 'Location',
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            internalValue: null as null | MBAddressObj,
            dialog: false,
            map: {
                base: null as any,
                marker: null as any,
                lastDoubleClick: NaN, // We won't move the pin on double click.
            },
        };
    },
    computed: {
        activeLocation(): null | MBAddressObj {
            return this.$store.state.activeLocation;
        },
        displayLocation(): null | string {
            return this.value?.place_name;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (this.internalValue !== value) {
                    this.internalValue = value;
                }
            },
        },
        internalValue(internalValue) {
            if (internalValue) {
                this.map.marker?.setLngLat(internalValue.center);
                this.map.marker?.addTo(this.map.base);

                const inView = this.map.base?.getBounds().contains(internalValue.center);

                this.map.base?.flyTo({
                    center: internalValue.center,
                    speed: inView ? 2 : 100,
                });
            } else {
                this.map.marker?.remove();
            }
            setTimeout(() => {
                // Resize the map manually after the iOS keyboard slides down.
                // Not sure why this doesn't happen automatically, since something responds to it sliding up.
                this.map.base?.resize();
            }, 1000);
        },
        dialog(dialog, previousDialog) {
            if (dialog && !previousDialog) {
                setTimeout(this.setupMap, 250);
            } else if (!dialog && previousDialog) {
                setTimeout(this.teardownMap, 250);
            }
        },
    },
    methods: {
        teardownMap() {
            // ...
        },
        async setupMap() {
            if (this.map.base) {
                return;
            }

            const Mapbox = await importMapboxGl();

            const defaultCenter = this.value?.center ?? this.activeLocation?.center ?? CENTER_OF_THE_USA;

            this.map.base = new Mapbox.Map({
                container: this.$refs.mapselector as HTMLDivElement,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: this.value?.center as LngLat ?? this.activeLocation?.center ?? CENTER_OF_THE_USA,
                zoom: defaultCenter === CENTER_OF_THE_USA ? 3 : 13,
                trackResize: true,
            });

            this.map.marker = new Mapbox.Marker({ draggable: true });

            if (this.internalValue) {
                this.map.marker.setLngLat(this.internalValue.center);
                this.map.marker.addTo(this.map.base);
            }

            this.map.base.on('load', () => {
                if (this.activeLocation) {
                    this.map.base.addSource('userpoint', {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [{
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: this.activeLocation.center,
                                }
                            }],
                        }
                    });

                    this.map.base.addLayer({
                        id: "userpoint",
                        type: "circle",
                        source: "userpoint",
                        paint: {
                            "circle-radius": 5,
                            "circle-color": "#3887be"
                        }
                    });
                }

                // Track double-clicks so we don't move the pin when we're just trying to zoom in.
                this.map.base.on('dblclick', () => {
                    this.map.lastDoubleClick = Date.now();
                });

                this.map.base.on('click', (event: any) => {
                    const { lng, lat } = event.lngLat;
                    this.lookUpCoordinates([lng, lat]);
                });

                this.map.marker.on('dragend', () => {
                    const coords = this.map.marker.getLngLat();
                    this.lookUpCoordinates([coords.lng, coords.lat]);
                });
            });
        },
        lookUpCoordinates: debounce(async function(this: any, coords: [number, number]) {
            const location = await reverseGeocode(coords, { limit: 1 });

            const DOUBLE_CLICK_THRESHOLD = 1000; // The debounce, plus whatever extra time the event needs after its last click.
            const justDoubleClicked = Date.now() - this.map.lastDoubleClick < DOUBLE_CLICK_THRESHOLD;

            if (!justDoubleClicked) {
                this.internalValue = location.features?.[0];
            }
        }, 500),
        confirmMapLocation() {
            this.$emit('change', this.internalValue);
            this.dialog = false;
        },
        discardMapLocation() {
            this.internalValue = this.value;
            this.dialog = false;
        }
    },
});
</script>
<style lang="postcss" scoped>
.activator {
    display: block !important;
}

.activator__label {
    /* match active label style */
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 4px;
    display: block;
}

.activator__action {
    color: var(--color-primary);
    font-weight: bold;
    font-size: 13px;
}

.activator--empty-prompt :deep(.v-input__append-inner) {
    width: 100%;
    position: absolute;
    top: 8px;
    left: -4px;

}
.activator--empty-prompt {
    & :deep(.v-input__slot:after),
    & :deep(.v-input__slot:before) {
        display: none;
    }
}
.activator :deep(.v-textarea textarea) {
    line-height: 1.1;
}

/* Having to set this as `content-class` knocks it out of scope. */
:deep(.location-selector-dialog-root) {
    background: #fff8;
    display: flex;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.autocomplete {
    position: absolute;
    top: var(--spacing-6);
    left: var(--spacing-6);
    right: 70px;
    z-index: 1;
    margin: 0;
}

.close-button {
    position: absolute;
    top: var(--spacing-8);
    right: var(--spacing-4);
    z-index: 1;
}

.map {
    flex: 1 1 600px;
}

.confirm-button {
    position: absolute;
    bottom: var(--spacing-6);
    left: 50%;
    right: auto;
    z-index: 1;
    margin: 0;
    transform: translateX(-50%);
}
</style>
